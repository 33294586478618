/*
 * @Author: huanqi.hu
 * @Date: 2024-12-18 09:27:17
 * @LastEditTime: 2024-12-18 14:32:43
 * @LastEditors: huanqi.hu
 * @FilePath: /gl-cloud-frontendc:/JobInitial-system/gl-system-frontend/src/lang/firmware/zh.js
 * @Description:
 */
export default {
    firmware: {
        createTag: '创建标签',
        editeTag: '编辑标签',
        tagName: '标签名称',
        pleaseEnter: '请输入',
        firmwareDevelopment: '固件开放（Scope）',
        tip1: '请选择组织',
        basicInfomation: '基本信息',
        details: '详情',
        tag: '标签',
        model: '模型',
        version: '版本',
        hashType: 'Hash类型',
        hashValue: 'Hash值',
        compileTime: '编译时间',
        description: '描述',
        fileSize: '文件大小',
        fileName: '文件名称',
        url: '文件路径',
        support: '支持以下版本固件升级',
        upgradeableVersion: '可升级版本',
        var: 'Var',
        organization: '组织',
        editeInfo: '编辑信息',
        defaultStatus: '默认状态',
        pleaseSelect: '请选择',
        upgradeRules: '特殊升级规则',
        upFrim: '可升级到此版本的固件',
        rule: '规则',
        ableUpVersion: '该固件可升级版本',
        upload: '上传',
        uploadFirmware: '上传固件',
        tag1: '标签',
        syncFirm: '同步固件',
        edite: '编辑',
        del: '删除',
        filters: '筛选',
        clear: '清除',
        editInfo: '编辑信息',
        editRules: '编辑规则',
        standardFirmware: '标准固件',
        customized: '定制固件',
        supplied: '客供固件',
        test: '测试固件',
        tiperr: '数据错误，未初始化Normal标签',
        tipwarning: '此操作将永久删除该标签, 是否继续?',
        cancelled: '已取消删除',
        name: '名称',
        type: '类型',
        CompileTime: '编译时间',
        device: '设备',
        upgrade_way: '更新方式',
        supported_version: '支持版本',
        uploadfirmware: '固件编辑',
        modificationfirmware: '固件修改',
        addFirmware: '添加固件',
        customer: '版本类型',
        release: '上传',
        revoke: '撤销',
        editfirmware: '固件编辑',
        viewfirmware: '查看固件',
        Undofirmware: '取消',
        Published: '发布',
        Unpublished: '未发布',
        action: '操作',
        Viewdetail: '查看详情',
        fw_type: '固件类型',
        fw_user: '用户',
        vendor: '供应商',
        hash_type: '哈希类型',
        hash_value: '哈希值',
        mac: 'MAC地址',
        ssid: 'SSID',
        searchbar_ip: 'IP地址'
    }
}
