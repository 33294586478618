/*
 * @Author: lijiaxin
 * @Date: 2024-07-02 18:37:32
 * @LastEditors: Warren
 * @LastEditTime: 2024-11-11 14:14:00
 * @FilePath: \gl-system-fronted\src\api\errorCode\index.js
 * @Description: 错误码
 */
export default {
    '-999': 'message.sendEmailOrPhoneFailed', /* 请重新登录确认用户身份 */
    '-1001': 'message.serverError', /* 服务器异常 */
    '-1002': 'message.i18n_invalidTokenWarning', /* token 异常 */
    '-1003': 'message.permissionError', /* 没有权限 */
    '-1004': 'message.tokenExpired', /* token 失效 */
    '-1005': 'message.addDeviceParam', /* 参数丢失 */
    '-1007': 'message.inputParametersError', /* 输入参数错误 */
    '-1008': 'message.i18n_invalidTokenWarning', /* 服务器忙，请稍后再试 */
    '-1010': 'message.accoutLoginInOtherPlace', /* 账号已在其它地方登录 */
    '-1011': 'message.expiredLogin', /* 登录过期 */
    '-1017': 'message.INVITE_HAS_ACCEPTED', /* 邀请已经被接受 */
    '-1020': 'message.userAlreadyExistInOrg', /* 此组织中已存在邀请用户 */
    '-1022': 'message.requestXssError', /* xss攻击提示 */
    '-1023': 'message.securityAuthorizationExpired', /* 安全页登录授权状态过期提示 */

    '-2001': 'message.invaliduser2psw', /* 登录账号密码错误 */
    '-2002': 'message.userunexist', /* 用户不存在 */
    '-2003': 'message.confirmPasswordValidateError', /* 密码不一致 */
    '-2004': 'message.passwordError', /* 密码错误 */
    '-2005': 'message.userNamealreadyexists', /* 该用户名已被注册 */
    '-2006': 'message.emailalreadyexists', /* 该邮箱已被注册 */
    '-2007': 'message.emailCodeError', /* 邮箱验证码错误 */
    '-2008': 'message.emailerror', /* 邮箱错误 */
    '-2009': 'message.emailCodeExpired', /* 邮箱验证码已过期 */
    '-2011': 'message.Two_factor_code_verification_failed_Please_try_again', /* 验证码错误 */
    // catch中已经处理，不需要显示 '-2012': '',
    '-2013': 'message.sendEmailCodeFailed', /* 发送邮件验证码失败 */
    '-2014': 'message.phoneCodeExpired', /* 手机验证码已过期 */
    '-2015': 'message.phoneCodeError', /* 手机验证码错误 */
    '-2016': 'message.notYourPhone', /* 当前手机号不是你的 */
    '-2017': 'message.phoneExists', /* 手机号已存在 */
    '-2018': 'message.phoneNotExist', /* 手机号不存在 */
    '-2019': 'message.invaliduser2psw', /* 登录账号密码错误 */
    // '-2020': 'signup.emailLimit', /* 3分钟内只能发送一次邮件 */
    '-2020': 'message.sendCodeTimesError', /* 验证码发送超过限制条数 */
    '-2021': 'message.regionMatch', /* 登录区域不匹配 */
    '-2022': 'message.VERIFICATION_CODE_COUNT_ERROR',
    '-2023': 'message.DEVICE_LIST_IS_NULL',
    '-2024': 'message.HAS_NOT_TRANSFER_DEVICE',
    '-2025': 'message.HAS_NOAUTH_DEVICE',
    '-2026': 'message.ORGID_IS_NULL',
    '-2027': 'message.HAS_NOT_RETURNED_DEVICE',
    '-2028': 'message.DEVICE_DDNS_MISMATCHING',
    '-2030': 'message.ORG_ID_IS_ILLEGAL',

    '-3001': 'message.unDevices', /* 未找到设备 */
    '-3002': 'message.devicesOffline', /* 该设备已下线 */
    '-3003': 'message.devicesTimeout', /* 设置该设备超时 */
    '-3005': 'message.fileLengthValidateError', /* catch已经处理，不需要额外显示，请选择 excel 文件导入 */
    '-3006': 'message.addDeviceExcelError', /* 请选择 excel 文件导入 */
    '-3007': 'message.DEVICE_IMAGE_FORMAT_ERROR',
    '-3008': 'message.DEVICE_IMAGE_SIZE_ERROR',
    '-3009': 'message.DEVICE_FIRMWARE_NOT_FOUND',
    '-3010': 'message.DEVICE_EXECUTION_FAILED',

    '-4001': 'message.one_of_the_devices_had_joined_to_another_site_to_site_network_please_remove_it_then_continue', /* 有一个设备已经加入到其它站点到站点网络，请移除后继续 */
    '-4002': 'message.infoIncorrect', /* 您输入的信息(MAC, S/N, DDNS/设备ID)不正确，请检查您输入的信息 */
    '-4003': 'message.DATA_DEPEND',
    '-4005': 'message.hostAlreadyExists', /* 品牌定制 host 已存在 */
    '-4009': 'message.TEMPLATE_NAME_EXIST',

    '-5001': 'message.SHARE_ERROR',
    '-5002': 'message.RESULT_ERROR',
    '-5003': 'message.gpsNotfound', /* 未找到GPS设备 */
    '-5004': 'message.error', /* 错误 */
    '-5005': 'message.REPETITION_SHARE_ERROR',

    '-6001': 'message.GROUP_EXISTS',
    '-6002': 'message.DEVICE_EXISTS',
    '-6003': 'message.TASK_EXISTS',
    '-6004': 'message.TEMPLATE_EXISTS',
    '-6005': 'message.SUBACCOUNT_EXISTS',
    '-6006': 'message.ALREADY_SHARED',
    '-6007': 'message.NOT_SUBACCOUNT',
    '-6008': 'message.serverError', /* 签名验证错误，修改了本机时间 */
    '-6009': 'message.TASK_LIMIT_ILLEGAL_ERR',
    '-6010': 'message.TASK_TYPE_UNSUPPORTED_ERR',
    '-6011': 'message.TASK_REQUEST_EMPTY_ERR',
    '-6012': 'message.finishAndStopfailure', /* 创建任务完成停止失败 */

    '-7001': 'message.FILE_NULL',
    '-7002': 'message.FORMAT_ERROR',
    '-7003': 'message.SIZE_ERROR',

    '-7004': 'message.domainLegal', /* 提交失败，域名不合法 */

    '-7005': 'message.AUTO_SSL_GOODCLOUD_FAILED',
    '-7006': 'message.AUTO_SSL_RTTY_SSH_FAILED',
    '-7007': 'message.AUTO_SSL_RTTY_WEB_FAILED',
    '-7008': 'message.USER_CONFIG_EXISTS',
    '-7009': 'message.domainExit', /* 域名已存在 */
    '-7010': 'message.domainExit', /* 域名已存在 */
    '-7011': 'message.domainExit', /* 域名已存在 */
    '-7012': 'message.guiClosed', /* GUI is closed */
    '-7013': 'message.EMAIL_CONFIG_ERROR',
    '-7014': 'message.domainError', /* 域名或ssl证书错误 */
    '-7015': 'message.sslError', /* 自动ssl证书错误 */
    '-7016': 'message.DNS_RECORD_ERROR',
    '-7017': 'message.DOMAIN_MODIFY_ERROR',
    '-7018': 'message.RPC_REQUEST_ERROR',
    '-7019': 'message.FAVORITE_COUNT_LIMIT',
    '-7020': 'message.FAVORITE_NAME_EXISTS',
    // 以下是新追加
    10000001: 'message.SERVER_ERROR', // 系统错误，多出现于系统重启，及捕获到未定义的异常
    10000002: 'message.INVALID_TOKEN', // token无效
    10000003: 'message.PERMISSION_ERROR', // 权限错误
    10000004: 'message.DATA_ERROR', // 数据错误
    10000015: 'message.REQUEST_BUSY_ERROR', // 频繁提交，用于限流

    10000016: 'message.SYSTEM_PARENT_ID_NOT_FOUND',
    10000017: 'message.SYSTEM_PARENT_APPLICATION_MUST_BE_FIRST_LEVEL',
    10000018: 'message.SYSTEM_DATA_TO_BE_MODIFIED_NOT_EXIST',
    10000019: 'message.SYSTEM_OBJECT_INFO_EMPTY',
    10000020: 'message.SYSTEM_ID_NOT_NULL',
    10000021: 'message.SYSTEM_ID_EMPTY',
    10000022: 'message.SYSTEM_APP_NAME_EMPTY',
    10000023: 'message.SYSTEM_APP_CODE_EMPTY',
    10000024: 'message.SYSTEM_ILLEGAL_APP_NAME',
    10000025: 'message.SYSTEM_APP_NAME_EXISTS',
    10000026: 'message.SYSTEM_INVALID_TIME_FORMAT',
    10000027: 'message.SYSTEM_INVALID_ARGUMENT_MULTIPARTFILE_NULL',
    10000028: 'message.SYSTEM_INVALID_ARGUMENT_OBJECTNAME_NULL',
    10000029: 'message.SYSTEM_MAC_EMPTY',
    10000030: 'message.SYSTEM_USER_ID_EMPTY',
    10000031: 'message.SYSTEM_STATUS_EMPTY',
    10000032: 'message.SYSTEM_INVALID_VERSION_NUMBER',
    10000033: 'message.SYSTEM_SQL_INVALID_PARAMETER',
    10000034: 'message.SYSTEM_TEMPERATURE_THRESHOLD_RANGE',

    10001001: 'message.USER_DATA_TO_BE_MODIFIED_NOT_EXIST',
    10001002: 'message.USER_OBJECT_INFO_EMPTY',
    10001003: 'message.USER_ID_NOT_NULL',
    10001004: 'message.USER_SEND_MODE_EMPTY',
    10001005: 'message.USER_RECIPIENT_ID_EMPTY',
    10001006: 'message.USER_SEND_STATUS_EMPTY',
    10001007: 'message.USER_ID_EMPTY',
    10001008: 'message.USER_USERNAME_EMPTY',
    10001009: 'message.USER_PHONE_EMPTY',
    10001010: 'message.USER_EMAIL_EMPTY',
    10001011: 'message.USER_UPDATE_EMAIL_EMPTY',
    10001012: 'message.USER_UPDATE_USER_TOKEN_EMPTY',
    10001013: 'message.USER_CODE_EMPTY',
    10001014: 'message.USER_ENTER_USER_INFO',
    10001015: 'message.USER_ACCOUNT_EMPTY',
    10001016: 'message.USER_PASSWORD_EMPTY',
    10001017: 'message.USER_SELECT_ORG',
    10001018: 'message.USER_MEMBERS_HAVE_BEEN_INVITED',
    10001019: 'message.USER_NOT_EXISTS',
    10001020: 'message.USER_USER_ID_EMPTY',
    10001021: 'message.USER_USER_ROLE_EMPTY',
    10001022: 'message.USER_ORG_NOT_EXISTS',
    10001023: 'message.USER_CANNOT_DELETE_YOURSELF',
    10001024: 'message.USER_CANNOT_DELETE_ADMINISTRATOR',
    10001025: 'message.USER_ORG_ID_EMPTY',
    10001026: 'message.USER_ENTER_MEMBER_ID',
    10001027: 'message.USER_ILLEGAL_MAIL_ADDRESS',
    10001028: 'message.USER_ILLEGAL_PHONE_NUMBER',
    10001029: 'message.USER_ROLES_EMPTY',
    10001030: 'message.USER_ENTER_MEMBER_INFO',
    10001031: 'message.USER_INVITATION_EXPIRED',
    10001032: 'message.USER_RE_PASSWORD_EMPTY',
    10001033: 'message.USER_ILLEGAL_USERNAME',
    10001034: 'message.USER_PASSWORD_NOT_MATCH',
    10001035: 'message.USER_USERNAME_EXISTS',
    10001036: 'message.USER_CURRENT_USER_ORG_ID_EMPTY',
    10001037: 'message.USER_CURRENT_USER_ORG_ID_ERROR',
    10001038: 'message.USER_CANNOT_FIND_ORG',
    10001039: 'message.USER_ORG_NOT_BELONG_VAR',
    10001040: 'message.USER_ORG_ROLE_EMPTY',
    10001041: 'message.USER_SELECT_ROLE',

    10002001: 'message.ORG_DEPARTMENT_NAME_EXIST',
    10002002: 'message.ORG_ORGANIZATION_NAME_EXIST',
    10002003: 'message.ORG_PARENT_ORG_NOT_EXIST',
    10002004: 'message.ORG_DATA_NOT_EXIST',
    10002005: 'message.ORG_PRIMARY_KEY_EMPTY',
    10002006: 'message.ORG_DELETE_DEPARTMENT_ERROR',
    10002007: 'message.ORG_OBJECT_INFO_EMPTY',
    10002008: 'message.ORG_ID_NOT_NULL',
    10002009: 'message.ORG_DEPARTMENT_NAME_EMPTY',
    10002010: 'message.ORG_PARENT_ID_EMPTY',
    10002011: 'message.ORG_ILLEGAL_ORG_NAME',
    10002012: 'message.ORG_DEPARTMENT_LEVEL_LIMIT',
    10002013: 'message.ORG_ILLEGAL_DEPARTMENT_NAME',
    10002014: 'message.ORG_ID_EMPTY',
    10002015: 'message.ORG_PARENT_ORG_NOT_EXISTS',
    10002016: 'message.ORG_ORG_NOT_EXISTS',
    10002017: 'message.ORG_PARENT_DEPARTMENT_CANNOT_BE_CHILD',
    10002018: 'message.ORG_ORG_ID_EMPTY',
    10002019: 'message.ORG_CANNOT_FIND_ORG',
    10002020: 'message.ORG_NOT_BELONG_VAR',
    10002021: 'message.ORG_CURRENT_USER_ORG_ID_EMPTY',
    10002022: 'message.ORG_DATA_TO_BE_MODIFIED_NOT_EXIST',
    10002023: 'message.ORG_ID_SET_NOT_NULL',
    10002024: 'message.ORG_SELECT_ORG',
    10002025: 'message.ORG_ROLE_TYPE_ERROR',
    10002026: 'message.ORG_ORGANIZATION_NAME_EMPTY',
    10002027: 'message.ORG_ORGANIZATION_CATEGORY_EMPTY',
    10002028: 'message.ORG_ENTER_ADMIN_INFO',
    10002029: 'message.ORG_ILLEGAL_ORGANIZATION_NAME',
    10002030: 'message.ORG_ILLEGAL_MAIL_ADDRESS',
    10002031: 'message.ORG_ILLEGAL_PHONE_NUMBER',
    10002032: 'message.ORG_NO_PERMISSION_CREATE_VAR',
    10002033: 'message.ORG_SELECT_VAR',
    10002034: 'message.ORG_NO_PERMISSION_CREATE_DEPARTMENT',
    10002035: 'message.ORG_ILLEGAL_ORG_ID',
    10002036: 'message.ORG_VAR_ID_EMPTY',
    10002037: 'message.ORG_NO_PERMISSION_TRANSFER_DEVICE',
    10002038: 'message.ORG_VAR_NOT_EXIST',
    10002039: 'message.ORG_DEVICE_NOT_EXIST_OR_NOT_BELONG_VAR',
    10002040: 'message.ORG_SELECT_DEPARTMENT',
    10002041: 'message.ORG_DEPARTMENT_NOT_EXIST',
    10002042: 'message.ORG_ORG_OR_VAR_NOT_EXIST',
    10002043: 'message.ORG_ORG_CONTAINS_DEVICES',
    10002044: 'message.ORG_FORM_ID_NOT_BELONG_CURRENT_USER',

    10003001: 'message.DEVICE_OBJECT_INFO_EMPTY',
    10003002: 'message.DEVICE_ID_NOT_NULL',
    10003003: 'message.DEVICE_MAC_EMPTY',
    10003004: 'message.DEVICE_REGION_EMPTY',
    10003005: 'message.DEVICE_ONLY_PERSONAL_USERS',
    10003006: 'message.DEVICE_DATA_TO_BE_MODIFIED_NOT_EXIST',
    10003007: 'message.DEVICE_CAN_NOT_FIND_DEVICE',
    10003008: 'message.DEVICE_RTTY_WEB_DO_NOT_ENABLE',
    10003009: 'message.DEVICE_RTTY_SSH_DO_NOT_ENABLE',
    10003010: 'message.DEVICE_SHARE_STATUS_PARAM_ERROR',
    10003011: 'message.DEVICE_ID_EMPTY',
    10003012: 'message.DEVICE_DEVICE_ID_EMPTY',
    10003013: 'message.DEVICE_ORG_ID_EMPTY',
    10003014: 'message.DEVICE_SN_EMPTY',
    10003015: 'message.DEVICE_DDNS_EMPTY',
    10003016: 'message.DEVICE_UPLOAD_LIMIT',
    10003017: 'message.DEVICE_GROUP_NAME_EMPTY',
    10003018: 'message.DEVICE_GROUP_NAME_EXISTS',
    10003019: 'message.DEVICE_DEVICE_INFO_NOT_EXIST',
    10003020: 'message.DEVICE_DEVICE_NOT_EXIST',
    10003021: 'message.DEVICE_SELECTED_DEVICE_MODEL_VERSIONS_INCONSISTENT',
    10003022: 'message.DEVICE_ORG_NOT_EXIST',
    10003023: 'message.DEVICE_DEVICE_NOT_FOUND',
    10003024: 'message.DEVICE_DEVICES_MUST_BELONG_SAME_VAR',
    10003025: 'message.DEVICE_DEVICES_MUST_BELONG_SAME_ORG',
    10003026: 'message.DEVICE_MODEL_CODE_EMPTY',
    10003027: 'message.DEVICE_FIRMWARE_VERSION_EMPTY',
    10003028: 'message.DEVICE_ILLEGAL_GROUP_NAME',
    10003029: 'message.DEVICE_GROUP_NOT_EXISTS',
    10003030: 'message.DEVICE_GROUP_NOT_BELONG_ORG',
    10003031: 'message.DEVICE_GROUP_ID_EMPTY',
    10003032: 'message.DEVICE_SAME_GROUP_MODEL_FIRMWARE',
    10003033: 'message.DEVICE_TAGS_EMPTY',
    10003034: 'message.DEVICE_SELECT_AT_LEAST_ONE_DEVICE',
    10003035: 'message.DEVICE_ILLEGAL_DEVICE',
    10003036: 'message.DEVICE_DEVICE_INFO_ERROR',
    10003037: 'message.DEVICE_SELECT_DEVICE',
    10003038: 'message.DEVICE_DEVICE_NAME_EMPTY',
    10003039: 'message.DEVICE_DEVICE_ID_LIST_EMPTY',

    10005001: 'message.ROLE_ROLE_NAME_EXIST',
    10005002: 'message.ROLE_DATA_TO_BE_MODIFIED_NOT_EXIST',
    10005003: 'message.ROLE_ID_SET_NOT_NULL',
    10005004: 'message.ROLE_ROLE_ASSIGNED_USER',
    10005005: 'message.ROLE_OBJECT_INFO_EMPTY',
    10005006: 'message.ROLE_ID_NOT_NULL',
    10005007: 'message.ROLE_ROLE_NAME_EMPTY',
    10005008: 'message.ROLE_ROLE_TYPE_EMPTY',
    10005009: 'message.ROLE_ILLEGAL_ROLE_NAME',
    10005010: 'message.ROLE_ROLE_NAME_EXISTS',
    10005011: 'message.ROLE_ID_EMPTY',
    10005012: 'message.ROLE_PERMISSION_NAME_EMPTY',
    10005013: 'message.ROLE_PERMISSION_URL_EMPTY',
    10005014: 'message.ROLE_PERMISSION_CATEGORY_EMPTY',
    10005015: 'message.ROLE_APP_CODE_EMPTY',
    10005016: 'message.ROLE_PERMISSION_GROUP_NAME_EMPTY',
    10005017: 'message.ROLE_PARENT_ID_EMPTY',
    10005018: 'message.ROLE_VIEWER_EXIST',
    10005019: 'message.ROLE_FIRST_GROUP_MUST_BE_VIEWER',
    10005020: 'message.ROLE_PERMISSION_ID_EMPTY',
    10005021: 'message.ROLE_PERMISSION_GROUP_ID_EMPTY',

    10006001: 'message.SYSTEM_ADMIN_UPDATE_TYPE_ERROR',

    10009003: 'message.TPA_PARAM_ERROR',
    10009004: 'message.TPA_ORG_EXIST',
    10009005: 'message.TPA_OWNER_EXIST',
    10009006: 'message.TPA_ORG_NO_EXIST',
    10009007: 'message.TPA_ILLEGAL_ORG',
    10009008: 'message.TPA_DEVICE_NO_EXIST',
    10009009: 'message.TPA_EMAIL_ERROR',
    10009010: 'message.TPA_DEV_NOT_BELONG_ERROR',
    10009011: 'message.TPA_DEV_NOT_VERSION',
    10009012: 'message.TPA_DEV_ALREADY_BINDED',

    10013001: 'message.IOT_DATA_NOT_EXIST',
    10013002: 'message.IOT_OBJECT_INFO_EMPTY',
    10013003: 'message.IOT_SN_EMPTY',
    10013004: 'message.IOT_PUSH_TYPE_EMPTY',
    10013005: 'message.IOT_PUSH_VERSION_EMPTY',
    10013006: 'message.IOT_LIMIT_TIME_EMPTY',
    10013007: 'message.IOT_ID_NOT_NULL',
    10013008: 'message.IOT_OPERATION_STATUS_EMPTY',
    10013009: 'message.IOT_MODEL_EMPTY',
    10013010: 'message.IOT_BRIGHTNESS_EMPTY',
    10013011: 'message.IOT_FAVORITE_NOT_EXIST',
    10013012: 'message.IOT_USER_ID_EMPTY',
    10013013: 'message.IOT_FAVORITES_NAME_EMPTY',
    10013014: 'message.IOT_DEFAULT_FLAG_EMPTY',
    10013015: 'message.IOT_FAVORITES_NAME_EXISTS',
    10013016: 'message.IOT_DATA_TO_DELETE_NOT_EXIST',
    10013017: 'message.IOT_ON_OFF_STATUS_EMPTY',
    10013018: 'message.IOT_ROOM_NOT_EXIST',
    10013019: 'message.IOT_RECIPIENT_ID_EMPTY',
    10013020: 'message.IOT_ROOM_NAME_EXISTS',
    10013021: 'message.IOT_ID_EMPTY',
    10013022: 'message.IOT_DATA_TO_BE_MODIFIED_NOT_EXIST',

    10014001: 'message.TEMPLATE_CANNOT_FIND_MODEL',
    10014002: 'message.TEMPLATE_CANNOT_FIND_VERSION',
    10014003: 'message.TEMPLATE_TARGET_IDS_EMPTY',
    10014004: 'message.TEMPLATE_SOURCE_ENTITY_ID_EMPTY',

    10018001: 'message.DICT_EXIST',
    10018002: 'message.DICT_LABEL_EXIST',
    10018003: 'message.DICT_NO_EXIST',
    10018004: 'message.DICT_LABEL_NO_EXIST',

    10022001: 'message.FIRMWARE_TAG_NOT_EXIST',
    10022002: 'message.FIRMWARE_UPLOAD_FAIL',
    10022003: 'message.FIRMWARE_CHECK_FAIL',
    10022004: 'message.FIRMWARE_INVALID_SORT_FIELD',
    10022005: 'message.FIRMWARE_INVALID_SORT_ORDER',
}
