/*
 * @Author: huanqi.hu
 * @Date: 2024-12-18 09:27:17
 * @LastEditTime: 2024-12-23 09:25:33
 * @LastEditors: huanqi.hu
 * @FilePath: /gl-system-frontend/src/lang/firmware/en.js
 * @Description:
 */
export default {
    firmware: {
        createTag: 'Create Tag',
        editeTag: 'Edite Tag',
        tagName: 'Tag Name',
        pleaseEnter: 'please enter',
        firmwareDevelopment: 'Firmware development（Scope）',
        tip1: 'Please select firmware organization',
        basicInfomation: 'Basic Infomation',
        details: 'Details',
        tag: 'Tag',
        model: 'Model',
        version: 'Version',
        hashType: 'Hash Type',
        hashValue: 'Hash Value',
        compileTime: 'Compile Time',
        description: 'Description',
        fileSize: 'File Size',
        fileName: 'File Name',
        url: 'Url',
        support: 'Support firmware upgrades for the following versions',
        upgradeableVersion: 'Upgradeable version',
        var: 'Var',
        organization: 'Organization',
        editeInfo: 'Edit information',
        defaultStatus: 'Default Status',
        pleaseSelect: 'Please Select',
        upgradeRules: 'Special upgrade rules',
        upFrim: 'Upgradeable to this version of firmware',
        rule: 'Rule',
        ableUpVersion: 'This firmware is an upgradable version',
        upload: 'Upload',
        uploadFirmware: 'Upload Firmware',
        tag1: 'TAG',
        syncFirm: 'Synchronize firmware',
        edite: 'Edit',
        del: 'Delete',
        filters: 'Filters',
        clear: 'Clear',
        editInfo: 'Edit Info',
        editRules: 'Edit Rules',
        standardFirmware: 'Standard firmware',
        customized: 'Customized firmware',
        supplied: 'Customer firmware',
        test: 'Test firmware',
        tiperr: 'Data error, Normal label not initialized',
        tipwarning: 'This operation will permanently delete the tag. Do you want to continue?',
        cancelled: 'Cancelled deletion',
        name: 'Name',
        type: 'Type',
        CompileTime: 'Compile Time',
        device: 'Device',
        upgrade_way: 'Upgrade Way',
        supported_version: 'Supported Version',
        uploadfirmware: 'Upload Firmware',
        modificationfirmware: 'Modification Firmware',
        addFirmware: 'Add firmware',
        customer: 'Customer',
        release: 'Release',
        revoke: 'Revoke',
        editfirmware: 'Edit Firmware',
        viewfirmware: 'View Firmware',
        Undofirmware: 'Undo',
        Published: 'Published',
        Unpublished: 'Unpublished',
        action: 'Action',
        Viewdetail: 'View detail',
        fw_type: 'Firmware Type',
        fw_user: 'Firmware User',
        vendor: 'Vendor',
        hash_type: 'Hash Type',
        hash_value: 'Hash Value'
    }
}
