/*
 * @Author: yuanwen
 * @Date: 2024-10-22 14:19:38
 * @LastEditors: yuanwen
 * @LastEditTime: 2025-01-03 12:02:31
 * @FilePath: /gl-system-fronted/src/lang/messageCenter/en.js
 * @Description: 消息中心英文翻译
 */
export default {
    messageCenter: {
        deleteMessage: 'Once the data is deleted, it cannot be retrieved. Are you sure you want to delete it?',
        messageManagement: 'Message Management',
        startDate: 'Start Date',
        endDate: 'End Date',
        addMessage: 'Add Message',
        modifyMessage: 'Modify Message',
        allMemberOfPlatform: 'All members of the platform',
        tableTitle: 'Title',
        notificationRecipients: 'Notification Recipients',
        messageStatus: 'Message Status',
        priority: 'Priority',
        owners: 'Owners',
        allMembers: 'All members',
        organization: 'Organization',
        self: 'Self',
        closeMessage: 'If you exit now, all changes will not be saved. Are you sure you want to exit?',
        previous: 'Previous',
        createAndSend: 'Create And Send',
        orgName: 'Organization Name',
        personalName: 'Self Name',
        urgent: 'Urgent',
        high: 'High',
        medium: 'Medium',
        low: 'Low',
        isSelected: 'Selected',
        var: 'Var',
        dispatchTime: 'Timed Dispatch',
        expirationTime: 'Expiration Time',
        creationDate: 'Creation Time',
        creator: 'Creator',
        messageDetails: 'Message Details',
        deliveryTime: 'Delivery Time',
        member: 'Member{s}',
        personalUser: 'Personal User',
        varNumAndType: '<b>{type}</b> of <b>{num}</b> VAR',
        organizationNumAndType: '<b>{type}</b> of <b>{num}</b> ORG',
        personalUserNumAndType: '<b>{num}</b> personal users',
        contentLengthPrompt: 'Make sure the content is within {num} characters.',
        pleaseSelectedReceivers: 'Please select a recipient.',
        deleteMessageTitle: 'Removal Message',
        selectedTip: 'Selected',
        ensureSendTimeIsEarly: 'Please ensure the scheduled send time is earlier than the expiration date.',
        lastUpdateTime: 'Last Updated Time',
        lastUpdateBy: 'Last Updated By',
        dispatchTimeIsOverdue: 'The sending time has expired, please reset it.',
        org: 'ORG',
        emailHasBeenSent: 'The email has been sent and can no longer be edited.',
        pleaseInputMessageDetails: 'Please enter the content of the message details.',
        inputMessageTitle: 'Please enter the message title.',
    }
}
