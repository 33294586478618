/*
 * @Author: yuanwen
 * @Date: 2024-10-24 10:20:37
 * @LastEditors: huanqi.hu
 * @LastEditTime: 2024-12-19 12:21:39
 * @FilePath: /gl-system-frontend/src/directive/index.js
 * @Description: 指令注册
 */
import Vue from 'vue'
import multiLineEllipsis from './multiLineEllipsis'
import ellipsis from './ellipsis'
import { TitleDirective } from './vTitle'
const directives = {
    multiLineEllipsis,
    ellipsis
}

const kes = Object.keys(directives)
Vue.use(TitleDirective)
if (kes.length) {
    kes.forEach(key => {
        Vue.directive(key, directives[key])
    })
}
