let tooltip
const popClass = 'at-title-pop-' + Date.now()

function tipInit (Vue) {
    const el = document.createElement('div')
    document.body.appendChild(el)

    tooltip = new Vue({
        data () {
            return {
                visible: false
            }
        },
        render (_h) {
            const _vm = this
            return _h(
                'el-popover',
                {
                    ref: 'title-pop',
                    attrs: {
                        value: _vm.visible,
                        trigger: 'manual',
                        'popper-class': 'gl-title-pop',
                        placement: 'top'
                    },
                    on: {
                        input (v) {
                            _vm.visible = v
                        }
                    }
                },
                [_h('span', { staticClass: popClass })]
            )
        }
    }).$mount(el)
}

function tipShow (el, arg) {
    let text

    if (el.$tooltipTitle) {
        if (!el.$tooltipTitleOverflow || el.clientWidth < el.scrollWidth) {
            text = el.$tooltipTitle
        }
    } else {
        const _input = el.querySelector('.el-input__inner')
        if (_input && _input.clientWidth < _input.scrollWidth) {
            text = _input.value
        }
    }

    if (text) {
        const popEl = document.querySelector('.' + popClass)
        if (arg === 'br') {
            popEl.innerText = String(text).replace(/,/g, '\n')
        } else {
            popEl.innerText = text
        }

        if (tooltip.$refs['title-pop'].referenceElm !== el) {
            tooltip.$refs['title-pop'].doDestroy()
            tooltip.$refs['title-pop'].referenceElm = el
        }

        tooltip.visible = true
    }
}

function tipHide (el) {
    if (tooltip.$refs['title-pop'].referenceElm === el) {
        tooltip.visible = false
    }
}

const TitleDirective = {}
TitleDirective.install = (Vue) => {
    Vue.directive('title', {
        bind: function (el, binding, vnode) {
            tooltip || tipInit(Vue)
            el.$tooltipTitleOverflow = vnode.data.attrs && vnode.data.attrs['show-overflow-tooltip']
            el.$tooltipTitle = binding.value
            const vmethods = vnode.child ? vnode.child.$options : vnode.context.$options
            vmethods.deactivated || (vmethods.deactivated = [])

            vmethods.deactivated.push(tipHide.bind(null, el))
            el.$tipShow = () => tipShow(el, binding.arg)
            el.$tipHide = () => tipHide(el)
            el.addEventListener('mouseenter', el.$tipShow)
            el.addEventListener('mouseleave', el.$tipHide)
        },
        update: function (el, binding) {
            el.$tooltipTitle = binding.value
        },
        unbind: function (el, binding) {
            tipHide(el)
            el.removeEventListener('mouseenter', el.$tipShow)
            el.removeEventListener('mouseleave', el.$tipHide)
        }
    })
}

export { TitleDirective }
